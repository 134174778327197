<template>
  <g :id="`Female55Avatar_${uniqueIdSuffixA}`">
    <g>
      <circle style="fill: #d0d0d0" cx="24.2" cy="47.2" r="23.6" />
    </g>
    <g>
      <g>
        <path
          :style="{ fill: ringColorHex }"
          :stroke="ringColorHex ? 'rgba(255,255,255,0.5)' : ''"
          d="M24.2,71.1c-13.2,0-23.9-10.7-23.9-23.9s10.7-23.9,23.9-23.9c13.2,0,23.9,10.7,23.9,23.9
				S37.4,71.1,24.2,71.1z M24.2,27.2c-11,0-20,9-20,20s9,20,20,20s20-9,20-20S35.2,27.2,24.2,27.2z"
        />
      </g>
    </g>
    <g>
      <defs>
        <circle
          :id="`SVGID_12345_${uniqueIdSuffixA}`"
          cx="24.2"
          cy="47.2"
          r="19.1"
        />
      </defs>
      <clipPath :id="`SVGID_6789_${uniqueIdSuffixB}`">
        <use
          :xlink:href="`#SVGID_12345_${uniqueIdSuffixA}`"
          style="overflow: visible"
        />
      </clipPath>
      <g :style="`clip-path: url(#SVGID_6789_${uniqueIdSuffixB})`">
        <path
          style="fill: #6d553e"
          d="M30.2,46.2c0,0-6.2,0.8-5.2,4.7h-1.5c1-3.9-5.2-4.7-5.2-4.7c-2.2-0.3-4.1,1.8-3.2,5.8
				c0.9,4,2.3,10.8,2.3,10.8c0.9-2.4,6.4-3,5.3-5h2.9c-1.1,2,4.5,2.6,5.3,5c0,0,1.4-6.8,2.3-10.8C34.3,48,32.5,45.9,30.2,46.2z"
        />
        <path
          style="fill: #4c4c4c"
          d="M20,36.6c-2.6,3.2-0.3,11.3-1.7,14.9c0,0,0,0,0,0l0.4-1c1.6-4.7,1-12.2,2.5-13.4
				c1.9-1.4,3.1-0.6,3.1-0.6S22.6,33.4,20,36.6z"
        />
        <path
          style="fill: #4c4c4c"
          d="M28.6,36.6c2.6,3.2,0.3,11.3,1.7,14.9c0,0,0,0,0,0l-0.4-1c-1.6-4.7-1-12.2-2.5-13.4
				c-1.9-1.4-3.1-0.6-3.1-0.6S26,33.4,28.6,36.6z"
        />
        <path
          style="fill: #404040"
          d="M17.8,63.6c-0.1,0-0.2-0.1-0.2-0.2v-5.5c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2v5.5
				C18.1,63.5,18,63.6,17.8,63.6z"
        />
        <path
          style="fill: #404040"
          d="M30.6,63.6c-0.1,0-0.2-0.1-0.2-0.2v-5.5c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2v5.5
				C30.9,63.5,30.8,63.6,30.6,63.6z"
        />
        <g id="XMLID_3_">
          <g>
            <path
              style="fill: #f5f5f5"
              d="M30.7,57.6l0,2.2L28.8,65l-4.2-2.1l0,0l5.6-5.7C30.4,57.4,30.5,57.5,30.7,57.6z"
            />
            <path
              style="fill: #d3ae84"
              d="M30.2,57.2l-5.6,5.7l0,0l-5.8-5.8c3.8-3.2,2.2-9.4,2.2-9.4l3.4,0l3.4,0
						C27.8,47.7,26.2,54.1,30.2,57.2z"
            />
            <path
              style="fill: #333333"
              d="M30.7,59.8l0-2.2c0.1,0.1,0.2,0.2,0.4,0.2l4,1c1.2,0.4,2.1,1.4,2.4,2.6l1.6,9.9l-8.5-0.1v0
						L30.7,59.8z"
            />
            <path
              style="fill: #f5f5f5"
              d="M18.8,57.1l5.8,5.8L20.4,65l-1.9-5.2l0-2.4C18.5,57.3,18.7,57.2,18.8,57.1z"
            />
            <path
              style="fill: #333333"
              d="M18.4,59.8l0.1,11.4l-8.5,0.1l1.6-9.9c0.3-1.2,1.2-2.2,2.4-2.6l3.7-1c0.3-0.1,0.5-0.3,0.7-0.5
						L18.4,59.8z"
            />
            <polygon
              style="fill: #ececec"
              points="24.6,62.9 28.8,65 30.7,59.8 30.6,71.2 30.6,71.2 24.3,71.1 18.6,71.2 18.4,59.8 
						20.4,65 24.6,62.9 24.6,62.9 					"
            />
          </g>
        </g>
        <path
          style="fill: #edc593"
          d="M24.3,32.8c2.7,0,7,2.2,7.1,8.4c0,0-1.2,7.1-1.7,8.4c-0.4,1.1-2.5,3.2-4.4,4.2
				c-0.4,0.2-1.6,0.2-2.1,0c-1.9-1.1-4-3.2-4.4-4.2c-0.5-1.3-1.7-8.4-1.7-8.4C17.3,35,21.5,32.8,24.3,32.8z"
        />
        <path
          style="fill: #8e6f50"
          d="M34.1,50.5c-1.7-6.4,2.1-19.3-9.8-18.9c-11.9-0.4-8.1,12.5-9.8,18.9c-1.7,6.4,4.4,12.9,4.4,12.9
				c-4.3-6.7-1.9-10-1.9-10c3.8-3.1,2.4-14.8,4.3-16.3c1.9-1.4,3.1-0.6,3.1-0.6s1.2-0.8,3.1,0.6c1.9,1.4,0.5,13.2,4.3,16.3
				c0,0,2.4,3.3-1.9,10C29.8,63.4,35.8,56.9,34.1,50.5z"
        />
        <path
          style="fill: #8e6f50"
          d="M33.6,50.7c-2.9-3.3,4.1-19.1-9.4-18c-13.5-1.1-6.5,14.7-9.4,18c-1.9,2.2-0.4,5.5-0.4,5.5
				S13.8,54.1,15,52c0.7-1.3,1.3,1.8,1.4-9.5c0.1-11,7.6-8.9,7.6-8.8v0c0,0,0,0,0,0c0,0,0,0,0,0v0c1-0.1,7.8-2.2,7.9,8.8
				c0.1,11.3,0.9,8.2,1.6,9.5c1.1,2.1,0.5,4.2,0.5,4.2S35.6,53,33.6,50.7z"
        />
        <path
          style="fill: #8e6f50"
          d="M24.3,36.4c0,0-4.1,0-3,3.3c1,2.9-1.7,7.3-3,7.7c0,0,3.3-3,2.2-7.7C19.6,35.5,24.3,36.4,24.3,36.4
				z"
        />
        <path
          style="fill: #d3ae84"
          d="M25.3,47.6c0.2-0.2,0.4-0.6,0.4-1.2c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4
				c0,0.3,0,0.4-0.2,0.6c-0.2,0.2-0.5,0.2-0.6,0.2c-0.2,0-0.4,0.1-0.4,0.3c0,0.2,0.1,0.4,0.3,0.4c0,0,0.1,0,0.2,0
				C24.5,48,24.9,47.9,25.3,47.6z"
        />
      </g>
    </g>
  </g>
</template>
<script>
export default {
  props: {
    ringColor: {
      type: String,
      default: "#b5b4b5",
    },
  },
  computed: {
    ringColorHex() {
      return this.ringColor;
    },
    uniqueIdSuffixA() {
      return Math.floor(Math.random() * (25 - 2) + 2);
    },
    uniqueIdSuffixB() {
      return Math.floor(Math.random() * (50 - 26) + 62);
    },
  },
};
</script>
